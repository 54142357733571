import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import RootComponent from "./components/RootComponent";

// window.Buffer = Buffer;

const queryClient = new QueryClient();

const storeDetails = [
  {
    fpisStoreId: "45612",
    seiStoreId: "45612",
  },
  {
    fpisStoreId: "2205",
    seiStoreId: "40606",
  },
  // {
  //   fpisStoreId: "38771",
  //   seiStoreId: "38771",
  // },
  // {
  //   fpisStoreId: "35394",
  //   seiStoreId: "35394",
  // },
  // {
  //   fpisStoreId: "36593",
  //   seiStoreId: "36593",
  // },
  // {
  //   fpisStoreId: "32924",
  //   seiStoreId: "32924",
  // },
  // {
  //   fpisStoreId: "32917",
  //   seiStoreId: "32917",
  // },
  // {
  //   fpisStoreId: "32983",
  //   seiStoreId: "32983",
  // },
  // {
  //   fpisStoreId: "35761",
  //   seiStoreId: "35761",
  // },
  // {
  //   fpisStoreId: "36584",
  //   seiStoreId: "36584",
  // },
  // {
  //   fpisStoreId: "41993",
  //   seiStoreId: "41993",
  // },
  // {
  //   fpisStoreId: "35800",
  //   seiStoreId: "35800",
  // },
  // {
  //   fpisStoreId: "35789",
  //   seiStoreId: "35789",
  // },
  // {
  //   fpisStoreId: "35790",
  //   seiStoreId: "35790",
  // },
  // {
  //   fpisStoreId: "71101",
  //   seiStoreId: "71101",
  // },
  // {
  //   fpisStoreId: "42125",
  //   seiStoreId: "42125",
  // },
  // {
  //   fpisStoreId: "27860",
  //   seiStoreId: "27860",
  // },
  // {
  //   fpisStoreId: "36627",
  //   seiStoreId: "36627",
  // },
  // {
  //   fpisStoreId: "38665",
  //   seiStoreId: "38665",
  // },
  // {
  //   fpisStoreId: "38667",
  //   seiStoreId: "38667",
  // },
  // {
  //   fpisStoreId: "27211",
  //   seiStoreId: "27211",
  // },
  // {
  //   fpisStoreId: "33075",
  //   seiStoreId: "33075",
  // },
  // {
  //   fpisStoreId: "38671",
  //   seiStoreId: "38671",
  // },
  // {
  //   fpisStoreId: "45613",
  //   seiStoreId: "45613",
  // },
  // {
  //   fpisStoreId: "47080",
  //   seiStoreId: "47080",
  // },
  // {
  //   fpisStoreId: "27575",
  //   seiStoreId: "27575",
  // },
  // {
  //   fpisStoreId: "45594",
  //   seiStoreId: "45594",
  // },
  // {
  //   fpisStoreId: "38844",
  //   seiStoreId: "38844",
  // },
  // {
  //   fpisStoreId: "38845",
  //   seiStoreId: "38845",
  // },
  // {
  //   fpisStoreId: "45591",
  //   seiStoreId: "45591",
  // },
  // {
  //   fpisStoreId: "45593",
  //   seiStoreId: "45593",
  // },
  // {
  //   fpisStoreId: "43463",
  //   seiStoreId: "43463",
  // },
  // {
  //   fpisStoreId: "2777",
  //   seiStoreId: "40504",
  // },
  // {
  //   fpisStoreId: "39444",
  //   seiStoreId: "39444",
  // },
  // {
  //   fpisStoreId: "36759",
  //   seiStoreId: "36759",
  // },
  // {
  //   fpisStoreId: "41858",
  //   seiStoreId: "41858",
  // },
  // {
  //   fpisStoreId: "81103",
  //   seiStoreId: "",
  // },
  // {
  //   fpisStoreId: "71102",
  //   seiStoreId: "",
  // },
  // {
  //   fpisStoreId: "33275",
  //   seiStoreId: "33275",
  // },
  // {
  //   fpisStoreId: "47040",
  //   seiStoreId: "47040",
  // },
  // {
  //   fpisStoreId: "39773",
  //   seiStoreId: "39773",
  // },
  // {
  //   fpisStoreId: "32879",
  //   seiStoreId: "32879",
  // },
  // {
  //   fpisStoreId: "45617",
  //   seiStoreId: "45617",
  // },
  // {
  //   fpisStoreId: "47200",
  //   seiStoreId: "47200",
  // },
  // {
  //   fpisStoreId: "41996",
  //   seiStoreId: "41996",
  // },
  // {
  //   fpisStoreId: "36774",
  //   seiStoreId: "36774",
  // },
  // {
  //   fpisStoreId: "40159",
  //   seiStoreId: "ALT6789",
  // },
  // {
  //   fpisStoreId: "12200",
  //   seiStoreId: "12200",
  // },
  // {
  //   fpisStoreId: "41613",
  //   seiStoreId: "41613",
  // },
  // {
  //   fpisStoreId: "41130",
  //   seiStoreId: "41130",
  // },
  // {
  //   fpisStoreId: "45371",
  //   seiStoreId: "45371",
  // },
  // {
  //   fpisStoreId: "29609",
  //   seiStoreId: "29609",
  // },
  // {
  //   fpisStoreId: "38445",
  //   seiStoreId: "38445",
  // },
  // {
  //   fpisStoreId: "29978",
  //   seiStoreId: "29978",
  // },
  // {
  //   fpisStoreId: "33116",
  //   seiStoreId: "33116",
  // },
  // {
  //   fpisStoreId: "41841",
  //   seiStoreId: "41841",
  // },
  // {
  //   fpisStoreId: "45180",
  //   seiStoreId: "45180",
  // },
  // {
  //   fpisStoreId: "17284",
  //   seiStoreId: "17284",
  // },
  // {
  //   fpisStoreId: "36258",
  //   seiStoreId: "36258",
  // },
  // {
  //   fpisStoreId: "2171",
  //   seiStoreId: "40778",
  // },
];

const subscribeMock = (callback) => {
  const mockActionCardProps = {
    listviewSelectedStores: storeDetails,
  };

  setTimeout(() => {
    callback(mockActionCardProps);
  }, 1000);

  return {
    unsubscribe: () => {},
  };
};

const mockCustomProps = {
  compRef: {
    mapLayerService: {
      openMultiStoreResendPriceWindow$: {
        subscribe: subscribeMock,
      },
    },
  },
  compRefClose: () => console.info("Mock close function called"),
};

const isLocalhost = window.location.hostname.includes("localhost");

if (window.location.href.includes("https://visops.7-eleven.com")) {
  window.console.debug = function () {};
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  //@ts-ignore
  rootComponent: isLocalhost
    ? (props) => (
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <RootComponent customProps={mockCustomProps} {...props} />
          </SnackbarProvider>
        </QueryClientProvider>
      )
    : //@ts-ignore
      ({ customProps }) => (
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <RootComponent customProps={customProps} />
          </SnackbarProvider>
        </QueryClientProvider>
      ),
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount, update } = lifecycles;
