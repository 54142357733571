
import { createApiClient } from "./apiClient";
import constants from "../constants/constants.json";
import { formServiceUrl } from "../util/apiUrlUtil";
import { IStorePriceReq, IStorePriceRes } from "../interface/IStorePrice";

const {
  urlConstants: { multiStoreView },
} = constants;

const { fetchLatestPrice, name: SERVICE_NAME } = multiStoreView;

const baseUrlFactory = async () => formServiceUrl(SERVICE_NAME);
const apiClient = createApiClient(baseUrlFactory);

export const getMultiStoreLatestPrice = async (
  reqPayload: IStorePriceReq[]
): Promise<IStorePriceRes[]> => {
  return await apiClient.post<IStorePriceRes[]>(
    fetchLatestPrice,
    reqPayload
  );
};
