import { Box, Button, Chip, Paper } from "@mui/material";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import {
  ISendExportedFailedSummary,
  ISendStorePriceReq,
  ISendUserDetailReq,
  ISendUserStorePriceReq,
  IStorePriceReq,
  IStorePriceRes,
} from "../interface/IStorePrice";
import {
  useMultiStoreFetchLatestPrice,
  useSendLatestProposedPrice,
} from "../hooks/multiStoreApiHooks";
import ErrorDisplay from "./error/ErrorDisplay";
import SESkeletonLoader from "./loader/SESkeletonLoader";
import { AxiosError } from "axios";
import { getUserId, getUserName } from "../util/appUtil";
import { useSnackbar } from "notistack";
import SELoaderWithBackdrop from "./loader/SELoaderWithBackDrop";
import { format } from "date-fns";
import ConfirmationModal from "../confirmationWindow/ConfirmationModal";

interface PriceViewProps {
  size: { width: number; height: number };
  setSize: React.Dispatch<
    React.SetStateAction<{ width: number; height: number }>
  >;
  selectedStoreIdList: IStorePriceReq[];
  setIsPreviewScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setStoreSummary: React.Dispatch<
    React.SetStateAction<ISendExportedFailedSummary>
  >;
}
const rowHeight = 42;
const headerHeight = 312;
const extraPadding = 50;
const PriceView: React.FC<PriceViewProps> = ({
  size,
  selectedStoreIdList = [],
  setIsPreviewScreen,
  setStoreSummary,
  setSize,
}) => {
  const [storePrice, setStorePrice] = useState<IStorePriceRes[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // State for error message
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  //Call Fetch API
  const {
    data: fetchedData,
    error: fetchError,
    isLoading: fetchLoading,
    isFetching,
    refetch,
  } = useMultiStoreFetchLatestPrice(selectedStoreIdList);

  // Show loading state while fetching
  const isLoadingData = fetchLoading || isFetching;

  // Update store data when fetch completes
  useEffect(() => {
    if (fetchedData && !isFetching) {
      console.log("Fetched Latest Proposed Price {}", fetchedData);

      setStorePrice(fetchedData);
      setSize({
        width: size?.width,
        height: fetchedData.length * rowHeight + (56 + 20 + 180),
      });
    }
  }, [fetchedData, isFetching]);

  // Handle fetch errors
  useEffect(() => {
    if (fetchError) {
      console.error("Error fetching data stores :", fetchError);
      if (fetchError instanceof AxiosError) {
        const message =
          fetchError.response?.data?.message ||
          fetchError.message ||
          "An error occurred";
        setErrorMessage(message); // Set the error message
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    }
  }, [fetchError]);

  //Update product Name
  const productNames = useMemo(() => {
    if (!fetchedData || fetchedData.length === 0) return []; // Ensure fetchedData is available
    return Array.from(
      new Set(
        fetchedData.flatMap((store) =>
          store.productList.map((product) => product.productName)
        )
      )
    );
  }, [fetchedData]);

  //Date Format
  const formatDateTime = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return format(date, "MM/dd/yy HH:mm:ss");
  };

  // Dymanically update column
  const dynamicColumns = useMemo(() => {
    if (!productNames || productNames.length === 0) return []; // If no product names, return empty columns
    return productNames.flatMap((productName) => [
      {
        header: `${productName}  Last Export Time`,
        accessorKey: `${productName}_lastExportTime`, // Unique key for last export time column
        Cell: ({ row }) => {
          const product = row.original.productList.find(
            (p) => p.productName === productName
          );
          const lastExportDateTime = product
            ? product.lastExportedDateTime
            : null;
          return formatDateTime(lastExportDateTime);
        },
      },
      {
        header: `${productName} Effective Date`,
        accessorKey: `${productName}_effectiveDate`, // Unique key for effective date column
        Cell: ({ row }) => {
          const product = row.original.productList.find(
            (p) => p.productName === productName
          );
          const effectiveDateTime = product ? product.effectiveDateTime : null;
          return formatDateTime(effectiveDateTime);
        },
      },
      {
        header: `${productName} Price`,
        accessorKey: `${productName}_price`, // Unique key for price column
        Cell: ({ row }) => {
          const product = row.original.productList.find(
            (p) => p.productName === productName
          );
          return product ? product.price || "N/A" : "N/A";
        },
      },
    ]);
  }, [productNames]); // Recompute when productNames change

  //  All Column Details with dynamic
  const columns = useMemo<MRT_ColumnDef<IStorePriceRes>[]>(
    () => [
      {
        header: "Store ID",
        accessorKey: "storeId",
        enableHiding: false,
      },
      {
        header: "SEI ID",
        accessorKey: "seiId",
      },
      {
        header: "Price Source",
        accessorKey: "priceSource",
      },
      // Dynamic columns for each product
      ...dynamicColumns,
    ],
    [dynamicColumns]
  );
  // Update column visibility when columns are changed
  useEffect(() => {
    const initialColumnVisibility: Record<string, boolean> = {};
    columns.forEach((column) => {
      // Make `storeId` and `seiId` visible by default
      if (
        column.accessorKey === "storeId" ||
        column.accessorKey === "seiId" ||
        column.accessorKey === "priceSource" ||
        column.accessorKey === "Regular_lastExportTime" ||
        column.accessorKey === "Regular_price" ||
        column.accessorKey === "Diesel_lastExportTime" ||
        column.accessorKey === "Diesel_price"
      ) {
        initialColumnVisibility[column.accessorKey] = true;
      } else {
        // Hide other columns by default
        initialColumnVisibility[column.accessorKey] = false;
      }
    });
    setColumnVisibility(initialColumnVisibility);
  }, [columns]);

  useEffect(() => {
    if (storePrice.length > 0) {
      const newRowSelection = storePrice.reduce((acc, store) => {
        acc[store.storeId] = true; // Mark all rows as selected
        return acc;
      }, {} as MRT_RowSelectionState);
      setRowSelection(newRowSelection);
    }
  }, [storePrice]);

  const table = useMaterialReactTable({
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#d5d2d2",
        color: "black",
      },
    },
    muiTableBodyCellProps: {
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.grey[900]
            : theme.palette.grey[50],
      }),
    },
    muiTableContainerProps: {
      sx: { maxHeight: `${size.height - 180}px` },
    },

    columns,
    data: storePrice,
    enableRowSelection: true,
    enableStickyHeader: true,
    initialState: {
      density: "compact",
      expanded: true,
    },

    enableColumnPinning: true,
    enableFullScreenToggle: false,

    //Remove Pagination
    enablePagination: false,
    enableBottomToolbar: false,

    getRowId: (row) => row.storeId, //give each row a more useful id
    onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
    state: { rowSelection, columnVisibility }, //pass our managed row selection state to the table to use
    onColumnVisibilityChange: setColumnVisibility,
  });

  const { mutate } = useSendLatestProposedPrice();
  const { enqueueSnackbar } = useSnackbar();
  // On Submit
  const submitPriceExport = () => {
    console.log(" Submit Button is clicked ");
    setConfirmationOpen(false);
    setIsSubmitting(true);
    const userDetailsReq: ISendUserDetailReq = {
      id: getUserId(),
      name: getUserName(),
    };
    const sendProposedPriceList: ISendStorePriceReq[] = table
      .getSelectedRowModel()
      .rows.map((row) => {
        return row.original;
      });
    const sendUserProposedPrice: ISendUserStorePriceReq = {
      userDetails: userDetailsReq,
      storeList: sendProposedPriceList,
    };
    console.log(sendUserProposedPrice);
    mutate(sendUserProposedPrice, {
      onSuccess: (data) => {
        setStoreSummary(data);
        setIsSubmitting(false);
        setIsPreviewScreen(true);
        const failedSummaryHeight =
          data?.failedSummary?.length == 0
            ? 0
            : data?.failedSummary?.length * rowHeight +
              (headerHeight + extraPadding);
        const exportedSummaryHeight =
          data?.exportedSummary?.length == 0
            ? 0
            : data?.exportedSummary?.length * rowHeight +
              (headerHeight + extraPadding);
        setSize({
          width: size?.width,
          height: failedSummaryHeight + exportedSummaryHeight,
        });
        enqueueSnackbar("Successfully Pushed Proposed Price", {
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
          autoHideDuration: 6000,
        });
      },
      onError(error, variables, context) {
        setIsSubmitting(false);
        setIsPreviewScreen(false);
        console.error(error);
        enqueueSnackbar(
          "Unable to Push Posposed Price. Please check for any issues and try again.",
          {
            variant: "error",
            anchorOrigin: { horizontal: "right", vertical: "top" },
            autoHideDuration: 6000,
          }
        );
      },
    });
  };

  return (
    <>
      <SELoaderWithBackdrop open={isSubmitting} />
      <SESkeletonLoader
        isLoading={isLoadingData}
        variant="rectangular"
        width={size.width}
        height={size.height - 100}
      >
        {fetchError ? (
          <ErrorDisplay
            message={errorMessage || "An unexpected error occurred."}
          />
        ) : (
          <Box sx={{ p: 1 }}>
            <Paper
              sx={{
                borderRadius: "8px",
                maxHeight: `${size.height - 100}px`,
              }}
            >
              <MaterialReactTable table={table} />
            </Paper>
            <Box
              display="flex"
              alignItems="center"
              padding="16px"
              justifyContent={"flex-end"}
            >
              <Button
                color="success"
                disabled={Object.keys(rowSelection).length === 0}
                onClick={() => setConfirmationOpen(true)}
                startIcon={<SaveIcon />}
                size="small"
                variant="contained"
                sx={{
                  borderRadius: "4px",
                  height: "28px",
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        )}
      </SESkeletonLoader>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        setConfirmationOpen={setConfirmationOpen}
        submitPriceExport={submitPriceExport}
      />
    </>
  );
};

export default PriceView;
