import { createApiClient } from "./apiClient";
import constants from "../constants/constants.json";
import { ISendExportedFailedSummary, ISendUserStorePriceReq } from "../interface/IStorePrice";
import { formServiceUrl } from "../util/apiUrlUtil";

const {
  urlConstants: { multiStoreAction },
} = constants;

const { sendLatestPrice, name: SERVICE_NAME } = multiStoreAction;

const baseUrlFactory = async () => formServiceUrl(SERVICE_NAME);
const apiClient = createApiClient(baseUrlFactory);

export const sendLatestProposedPrice = (
  data: ISendUserStorePriceReq
) => {
  return apiClient.post<ISendExportedFailedSummary>(
    sendLatestPrice,
    data
  );
};
