import {
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import SEDialog from "../core/SEDialog";

const ConfirmationModal = ({
  isOpen,
  setConfirmationOpen,
  submitPriceExport,
}) => {
  return (
    <SEDialog
      open={isOpen}
      title={"Price Export"}
      onClose={() => setConfirmationOpen(false)}
      footer={[
        <Button
          onClick={() => setConfirmationOpen(false)}
          variant="outlined"
          color="error"
          size="small"
        >
          Cancel
        </Button>,
        <Button
          variant="contained"
          color="success"
          size="small"
          onClick={submitPriceExport}
        >
          Submit
        </Button>,
      ]}
    >
      <Typography
        variant="body1"
        gutterBottom
        sx={{ fontWeight: "medium", color: "text.secondary" }}
      >
        Are you sure you want to export the pricing data?
      </Typography>
    </SEDialog>
  );
};

export default ConfirmationModal;
