import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "@mui/material/styles";

interface SELoaderWithBackdropProps {
  open: boolean;
}

const SELoaderWithBackdrop: React.FC<SELoaderWithBackdropProps> = ({
  open,
}) => {
  const theme = useTheme();

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default SELoaderWithBackdrop;
