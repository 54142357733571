import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExportedPriceTable from "./table/ExportedPriceTable";
import WarningPriceTable from "./table/WarningPriceTable";

const PreviewComponent = ({ storeSummary }) => {
  console.log("Preview Component", storeSummary);

  return (
    <>
      {storeSummary.exportedSummary.length > 0 && (
        <Accordion
          id="exportedSummary"
          defaultExpanded
          sx={{
            background: "linear-gradient(45deg, #f1f9f1, #66bb6a)", // Darker shade of green
            color: "black",
            borderRadius: "8px",
            marginBottom: "16px",
            "&:hover": { boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)" },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="exportedSummary-content"
            id="exportedSummary-header"
            sx={{
              color: "black",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
            >
              Exported Price Summary -
              <span style={{ color: "#FF8C00" }}>
                {storeSummary.exportedSummary.length}
              </span>
              {` ${
                storeSummary.exportedSummary.length > 1 ? " stores" : " store"
              } `}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ExportedPriceTable
              exportedSummary={storeSummary.exportedSummary}
            ></ExportedPriceTable>
          </AccordionDetails>
        </Accordion>
      )}
      {storeSummary.failedSummary.length > 0 && (
        <Accordion
          id="warningSummary"
          defaultExpanded
          sx={{
            background: "linear-gradient(45deg, #FFF9C4, #FFCC80)", // Light yellow to soft orange gradient
            borderRadius: "8px",
            marginBottom: "16px",
            "&:hover": { boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)" },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="exportedSummary-content"
            id="exportedSummary-header"
            sx={{
              color: "black",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
            >
              Exported Price Warning -
              <span style={{ color: "#FF8C00" }}>
                {storeSummary.failedSummary.length}
              </span>
              {` ${
                storeSummary.failedSummary.length > 1 ? " stores" : " store"
              } `}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <WarningPriceTable
              warningSummary={storeSummary.failedSummary}
            ></WarningPriceTable>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default PreviewComponent;
