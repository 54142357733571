import React, { useEffect, useState } from "react";
import { Box, CssBaseline, Paper, ThemeProvider } from "@mui/material";
import { themeDef } from "../theme/theme";
import ResizableDraggableContainer from "./resizable/ResizableDraggableContainer";
import { useResizableContainerWindowSettings } from "../hooks/useResizableContainerWindowSettings";
import AppHeader from "./header/AppHeader";
import PriceView from "./PriceView";
import EmptyStateScreen from "./emptyState/EmptyStateScreen";
import {
  ISendExportedFailedSummary,
  IStorePriceReq,
} from "../interface/IStorePrice";
import PreviewComponent from "./PreviewComponent";

interface RootProps {
  listviewSelectedStores: IStorePriceReq[];
}

const RootComponent = ({ customProps }) => {
  const [rootProps, setRootProps] = useState<RootProps>();
  const [noStoreMessage, setNoStoreMessage] = useState({
    title: "",
    body: "",
  }); // If No Store selected
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 1200,
    height: 180, // Initial compact height
  });
  const [isMaximized, setIsMaximized] = useState<boolean>(false); // Maximize and Minimize
  const { handleOnDrag, position, toggleFullScreen } =
    useResizableContainerWindowSettings();
  const [isPreviewScreen, setIsPreviewScreen] = useState<boolean>(false);
  const [storeSummary, setStoreSummary] =
    useState<ISendExportedFailedSummary>();

  // Intial Load
  useEffect(() => {
    const subscription =
      customProps?.compRef?.mapLayerService?.openMultiStoreResendPriceWindow$?.subscribe(
        (data: RootProps) => {
          console.log("Multi Store resend proposed Price --> ", data);
          setRootProps(data);
        }
      );
    return () => subscription.unsubscribe();
  }, []);

  // Whenever we udpate the list view
  useEffect(() => {
    console.log(
      "List view selected stores --> ",
      rootProps?.listviewSelectedStores
    );

    if (rootProps?.listviewSelectedStores?.length <= 0) {
      setNoStoreMessage({
        title: "No Stores Selected from list",
        body: "Please select stores to push proposed price",
      });
    }
  }, [rootProps?.listviewSelectedStores]);

  const toggleMaximize = () => {
    if (!isMaximized) {
      setSize({
        width: window.innerWidth - 45,
        height: size?.height,
      });
      toggleFullScreen(!isMaximized);
    } else {
      setSize({ width: 1200, height: size?.height });
      toggleFullScreen(!isMaximized);
    }
    setIsMaximized(!isMaximized);
  };
  return (
    <>
      <ThemeProvider theme={themeDef}>
        <CssBaseline />
        <ResizableDraggableContainer
          size={size}
          setSize={setSize}
          minConstraints={[1200, 180]}
          maxConstraints={[window.innerWidth - 50, window.innerHeight - 50]}
          position={position}
          onDrag={handleOnDrag}
        >
          <Paper
            style={{
              position: "absolute",
              flexGrow: 1,
              overflow: "visible",
              display: "flex",
              flexDirection: "column",
              height: size.height,
              width: size.width,
              cursor: "default",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.12), 0 0 1px rgba(0,0,0,0.1)",
              transition: "height 0.3s ease-in-out",
            }}
          >
            <Box className="draggable-handle">
              <AppHeader
                toggleMaximize={toggleMaximize}
                isMaximized={isMaximized}
                closeApp={() => {
                  // resetStore();
                  customProps.compRefClose();
                }}
              />
            </Box>

            <>
              {rootProps?.listviewSelectedStores?.length == 0 ? (
                <EmptyStateScreen noStoreMessage={noStoreMessage} />
              ) : isPreviewScreen ? (
                <Box
                  sx={{
                    flex: 1,
                    overflow: "auto",
                    padding: "16px",
                    maxHeight: `calc(${size.height}px - 100px)`,
                    "& > .MuiStack-root": {
                      padding: "0 8px",
                      marginBottom: "16px",
                    },
                  }}
                >
                  <PreviewComponent
                    storeSummary={storeSummary}
                  ></PreviewComponent>
                </Box>
              ) : (
                <>
                  <PriceView
                    selectedStoreIdList={rootProps?.listviewSelectedStores}
                    size={size}
                    setSize={setSize}
                    setIsPreviewScreen={setIsPreviewScreen}
                    setStoreSummary={setStoreSummary}
                  />
                </>
              )}
            </>
          </Paper>
        </ResizableDraggableContainer>
      </ThemeProvider>
    </>
  );
};

export default RootComponent;
