import React, { useMemo } from "react";
import { format } from "date-fns";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { ISendStoreSummaryRes } from "../../interface/IStorePrice";

interface ExportedPriceTableProps {
  exportedSummary: ISendStoreSummaryRes[]; // Array of stores
}
const ExportedPriceTable = ({ exportedSummary }: ExportedPriceTableProps) => {
  // Update product Name
  const productNames = useMemo(() => {
    if (!exportedSummary || exportedSummary.length === 0) return []; // Ensure fetchedData is available
    return Array.from(
      new Set(
        exportedSummary.flatMap((store) =>
          store.productList.map((product) => product.productName)
        )
      )
    );
  }, [exportedSummary]);

  //Date Format
  const formatDateTime = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return format(date, "MM/dd/yy HH:mm:ss");
  };

  // Dymanically update column
  const dynamicColumns = useMemo(() => {
    if (!productNames || productNames.length === 0) return []; // If no product names, return empty columns
    return productNames.flatMap((productName) => [
      {
        header: `${productName} Effective Date`,
        accessorKey: `${productName}_effectiveDate`, // Unique key for effective date column
        Cell: ({ row }) => {
          const product = row.original.productList.find(
            (p) => p.productName === productName
          );
          const effectiveDateTime = product
            ? product.displayedEffectiveDateTime
            : null;
          return formatDateTime(effectiveDateTime);
        },
      },
      {
        header: `${productName} Price`,
        accessorKey: `${productName}_price`, // Unique key for price column
        Cell: ({ row }) => {
          const product = row.original.productList.find(
            (p) => p.productName === productName
          );
          return product ? product.displayedPrice || "N/A" : "N/A";
        },
      },
    ]);
  }, [productNames]);

  //  All Column Details with dynamic
  const columns = useMemo<MRT_ColumnDef<ISendStoreSummaryRes>[]>(
    () => [
      {
        header: "Store ID",
        accessorKey: "storeId",
        enableHiding: false,
      },
      {
        header: "Price Source",
        accessorKey: "displayedPriceSource",
      },
      // Dynamic columns for each product
      ...dynamicColumns,
    ],
    [dynamicColumns]
  );
  return (
    <>
      <MaterialReactTable
        columns={columns}
        enableFullScreenToggle={false}
        data={exportedSummary}
        enableColumnPinning
        enableBottomToolbar={false}
        enableStickyHeader
        enablePagination={false}
        muiTableContainerProps={{ sx: { maxHeight: "calc(100vh - 230px)" } }}
        initialState={{ density: "compact" }}
        columnFilterDisplayMode="popover"
      />
    </>
  );
};

export default ExportedPriceTable;
