import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ISendUserStorePriceReq, IStorePriceReq, IStorePriceRes } from "../interface/IStorePrice";
import { getMultiStoreLatestPrice } from "../api/viewLatestPriceApi";
import { sendLatestProposedPrice } from "../api/sendLatestPriceApi";

export const useMultiStoreFetchLatestPrice = (
   iStorePriceReq: IStorePriceReq[] 
) => {
  return useQuery<IStorePriceRes[], Error>(
    ["multiStoreFetchLatestPrice", iStorePriceReq],
    () => {
        return getMultiStoreLatestPrice(iStorePriceReq);
    },
    {
      enabled: Boolean(iStorePriceReq?.length),
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 2,
      onError: (error) => {
        console.error("Error fetching control data:", error);
      },
    }
  );
};

export const useSendLatestProposedPrice = () => {
  return useMutation({
    mutationFn: (data: ISendUserStorePriceReq) =>
      sendLatestProposedPrice(data),
    onSuccess: () => {
      // todo - update data and loading state
    },
    onError: (error: AxiosError) => {
      // todo stop loading and show snackbar
    },
  });
};