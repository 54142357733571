import React from "react";
import { Box, Typography, Button } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";

interface EmptyStateScreenProps {
  noStoreMessage: {
    title: string;
    body: string;
  };
}

const EmptyStateScreen: React.FC<EmptyStateScreenProps> = ({
  noStoreMessage,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: "24px",
        textAlign: "center",
        backgroundColor: "#f5f5f5",
      }}
    >
      <StoreIcon
        sx={{
          fontSize: 64,
          color: "primary.main",
          marginBottom: 2,
        }}
      />
      <Typography variant="h5" gutterBottom>
        {noStoreMessage?.title || " No Stores Selected"}
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        {noStoreMessage?.body ||
          "Please select one or more stores to push proposed price"}
      </Typography>
    </Box>
  );
};

export default EmptyStateScreen;
